import {EventDetailsButtonStyle, HEADER_LAYOUT} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import type {PageComponentSettings} from './stylesParams'
import {PageComponentTextSettings} from './settingsParams'

// for params that may come from server
export const defaultStyleParams = (rtl: boolean): Partial<PageComponentSettings> => ({
  buttonStyle: EventDetailsButtonStyle.FULL,
  formButtonStyle: EventDetailsButtonStyle.FULL,
  headerLayout: HEADER_LAYOUT.CLASSIC,
})

export const defaultTexts = (
  settings: PageComponentSettings,
  t: TFunction,
  responsive = false,
): Partial<PageComponentTextSettings> => ({
  guestsTitleText: t('guestsTitleText'),
  rsvpButtonText: responsive ? t('listTicketsButtonText') : t('listRSVPButtonText'),
  timeAndLocationTitleText: t('timeAndLocationTitleText'),
  aboutTitleText: t('aboutEventTitleText'),
  scheduleTitleText: t('scheduleTitleText'),
  ticketsTitleText: t('ticketsSectionTitleText'),
  readMoreText: t('readMoreText'),
  readLessText: t('readLessText'),
  shareTitleText: t('shareTitleText'),
  ...{
    rsvpEventButtonText: settings?.texts?.rsvpButtonText ?? t('listRSVPButtonText'),
    ticketedEventButtonText: settings?.texts?.rsvpButtonText ?? t('listTicketsButtonText'),
  },
})

export const fillSettingsDefaults = ({
  settings,
  rtl,
  responsive,
  t,
}: {
  settings: PageComponentSettings
  rtl: boolean
  responsive: boolean
  t: TFunction
}): PageComponentSettings => ({
  ...defaultStyleParams(rtl),
  ...(settings ?? ({} as PageComponentSettings)),
  texts: {
    ...defaultTexts(settings, t as TFunction, responsive),
    ...(settings?.texts ?? {}),
  } as PageComponentTextSettings,
})
