import {createReducer} from '@reduxjs/toolkit'
import {closeNativeModal, _openNativeModal, openUpgradeToPremium, openCheckoutUnavailable} from '../actions/modals'
import {ModalsState} from '../types'
import {NativeModal} from '../components/modals/constants'

const defaultState: ModalsState<NativeModal> = {
  modalType: undefined,
  payload: undefined,
}

export const modals = createReducer(defaultState, builder => {
  builder
    .addCase(_openNativeModal, (state, action) => ({modalType: action.payload.type, payload: action.payload.payload}))
    .addCase(closeNativeModal, () => defaultState)
    .addCase(openUpgradeToPremium.pending, () => defaultState)
    .addCase(openCheckoutUnavailable.pending, () => defaultState)
})
